import { Patient } from "./../pages/patient";
import { BuySubscriptionDialog } from "modals/buy-subscription";
import { Appointments } from "./../pages/appointments";
import { autoinject } from "aurelia-framework";
import { AuthenticationService } from "./authentication-service";
import { IAppointment, IAppointmentRequest, ISubscription } from "./interfaces";

enum controller {
  AppointmentTypes = "AppointmentTypes",
  AppointmentRequests = "AppointmentRequests",
  PatientAppointmentRequests = "PatientAppointmentRequests",
  Users = "users",
  CreditCards = "creditcards",
  Appointments = "appointments",
  TodaysAppointments = "TodaysAppointments",
  PatientAppointments = "PatientAppointments",
  SubscriptionTypes = "SubscriptionTypes",
  Subscriptions = "Subscriptions",
  PatientSubscriptions = "PatientSubscriptions",
  Patients = "Patients",
  ActiveSubscriptions = "ActiveSubscriptions",
  Services = "Services",
  Birthdays = "Birthdays",
  Transactions = "Transactions",
}

@autoinject()
export class DataService {
  _authenticationService: AuthenticationService;

  constructor(authenticationService: AuthenticationService) {
    this._authenticationService = authenticationService;
  }

  //#region User Data Service Methods
  async getUserFromServerAsync() {
    return await this.getSingleDataFromServerAsync(controller.Users, null);
  }
  async updateUserAsync(user: any) {
    return await this.updateSingleDataAsync(controller.Users, user);
  }
  //#endregion

  //#region Appointment Type Data Service Methods

  async getAppointmentTypesAsync() {
    return await this.getListDataFromServerAsync(
      controller.AppointmentTypes,
      null
    );
  }

  async postAppointmentRequestAsync(appointmentRequest: IAppointmentRequest) {
    return await this.postSingleDataAsync(
      controller.AppointmentRequests,
      appointmentRequest
    );
  }

  async deleteAppointmentRequestAsync(appointmentRequest: IAppointmentRequest) {
    return await this.deleteSingleDataFromServerAsync(
      controller.AppointmentRequests,
      appointmentRequest
    );
  }

  async getAppointmentRequestsAsync() {
    return await this.getListDataFromServerAsync(
      controller.AppointmentRequests,
      null
    );
  }

  async getPatientAppointmentRequestsAsync() {
    return await this.getListDataFromServerAsync(
      controller.PatientAppointmentRequests,
      null
    );
  }

  async getAppointmentsAsync() {
    return await this.getListDataFromServerAsync(controller.Appointments, null);
  }
  async getTodaysAppointmentsAsync() {
    return await this.getListDataFromServerAsync(
      controller.TodaysAppointments,
      null
    );
  }
  async getAllAppointmentsAsync() {
    return await this.getListDataFromServerAsync(
      controller.PatientAppointments,
      null
    );
  }
  async updateAppointmentAsync(appointment: IAppointment) {
    return await this.updateSingleDataAsync(
      controller.Appointments,
      appointment
    );
  }

  async postAppointmentAsync(appointment: IAppointment) {
    return await this.postSingleDataAsync(controller.Appointments, appointment);
  }

  async getSavedCreditCardsAsync() {
    return await this.getListDataFromServerAsync(controller.CreditCards, null);
  }
  async updateSavedCreditCardAsync(creditCard: any) {
    return await this.updateSingleDataAsync(controller.CreditCards, creditCard);
  }
  async removeSavedCreditCardAsync(creditCard: any) {
    return await this.deleteSingleDataFromServerAsync(
      controller.CreditCards,
      creditCard
    );
  }

  async getSubscriptionTypesAsync() {
    return await this.getListDataFromServerAsync(
      controller.SubscriptionTypes,
      null
    );
  }

  async postSubscriptionAsync(patientSubscription: any) {
    return await this.postSingleDataAsync(
      controller.PatientSubscriptions,
      patientSubscription
    );
  }

  async getSubscriptionAsync() {
    return await this.getSingleDataFromServerAsync(
      controller.Subscriptions,
      null
    );
  }

  async getPatientAsync(patientId) {
    return await this.getSingleDataFromServerAsync(
      controller.Patients,
      patientId
    );
  }
  async getPatientsAsync(searchTerm: string) {
    return await this.getListDataFromServerAsync(controller.Patients, {
      searchTerm: searchTerm,
    });
  }
  async updatePatientAsync(patient: Patient) {
    return await this.updateSingleDataAsync(controller.Patients, patient);
  }
  async getActiveSubscriptionsAsync() {
    return await this.getListDataFromServerAsync(
      controller.ActiveSubscriptions,
      null
    );
  }

  async getServicesAsync() {
    return await this.getListDataFromServerAsync(controller.Services, null);
  }

  async getClientBirthdaysAsync(num: number) {
    return await this.getListDataFromServerAsync(controller.Birthdays, {
      days: num,
    });
  }

  async postSubscriptionServicesAsync(subscriptionServices: any[]) {
    return await this.postSingleDataAsync(
      controller.Services,
      subscriptionServices
    );
  }

  async getTransactionsAsync() {
    return await this.getListDataFromServerAsync(controller.Transactions, null);
  }

  async getListDataFromServerAsync(
    controller: controller,
    requestParameters: any
  ) {
    let fetchUrl = `/${controller}`;

    if (requestParameters != null)
      fetchUrl = `${fetchUrl}${this.constructQueryString(requestParameters)}`;

    const returnedData = await fetch(fetchUrl, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${this._authenticationService.session.token}`,
      },
    });

    const jsonUnpackedData = await returnedData.json();

    return jsonUnpackedData;
  }

  async getSingleDataFromServerAsync(controller: controller, dataId: any) {
    let fetchUrl = "";

    if (dataId != null) fetchUrl = `/${controller}/${dataId}`;
    else fetchUrl = `/${controller}`;

    const returnedData = await fetch(fetchUrl, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${this._authenticationService.session.token}`,
      },
    });

    const jsonUnpackedData = await returnedData.json();

    return jsonUnpackedData;
  }

  async getPdfDataFromServerAsync(requestParameters: {
    propertyId: number;
    clientId: number;
  }) {
    let fetchUrl = `/pdf`;

    if (requestParameters != null)
      fetchUrl = `${fetchUrl}${this.constructQueryString(requestParameters)}`;

    const returnedData = await fetch(fetchUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    });
  }

  async postSingleDataAsync(controller: controller, entity: any) {
    let fetchUrl = `/${controller}`;

    const returnedData = await fetch(fetchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${this._authenticationService.session.token}`,
      },
      body: JSON.stringify(entity),
    });

    const jsonUnpackedData = await returnedData.json();
    return jsonUnpackedData;
  }

  async updateSingleDataAsync(controller: controller, entity: any) {
    let fetchUrl = `/${controller}`;

    const returnedData = await fetch(fetchUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${this._authenticationService.session.token}`,
      },
      body: JSON.stringify(entity),
    });

    const jsonUnpackedData = await returnedData.json();
    return jsonUnpackedData;
  }

  async deleteSingleDataFromServerAsync(controller: controller, entity: any) {
    let fetchUrl = `/${controller}`;

    const returnedData = await fetch(fetchUrl, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${this._authenticationService.session.token}`,
      },
      body: JSON.stringify(entity),
    });
  }

  async getTemplateDataFromServerAsync(controller: controller) {
    let fetchUrl = `/${controller}`;

    const returnedData = await fetch(fetchUrl, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${this._authenticationService.session.token}`,
      },
    });

    const jsonUnpackedData = await returnedData.json();

    return jsonUnpackedData;
  }

  private constructQueryString(parameters) {
    var queryString = "";

    for (var prop in parameters) {
      if (parameters.hasOwnProperty(prop) && parameters[prop] != null) {
        queryString +=
          queryString == ""
            ? `?${prop}=${parameters[prop]}`
            : `&${prop}=${parameters[prop]}`;
      }
    }

    return queryString;
  }
}
