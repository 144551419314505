import { Store } from "aurelia-store";
import { State } from "./state";
import { autoinject } from "aurelia-framework";
import { DataService } from "./data-service";
import {
  setIsAdmin,
  updateActiveSubscriptions,
  updateAllAppointments,
  updateAppointmentRequests,
  updateAppointmentTypes,
  updateAppointments,
  updateClientBirthdays,
  updateCreditCards,
  updatePatient,
  updatePatientAppointmentRequests,
  updatePatients,
  updateServices,
  updateSubscription,
  updateSubscriptionTypes,
  updateTodaysAppointments,
  updateTransactions,
  updateUser,
} from "./state-actions";
import { IAppointment, IAppointmentRequest, ISubscription } from "./interfaces";

@autoinject()
export class StateManager {
  _store: Store<State>;
  _dataService: DataService;

  constructor(dataService: DataService, store: Store<State>) {
    this._dataService = dataService;
    this._store = store;
  }

  async setIsAdmin(bool: boolean) {
    this._store.dispatch(setIsAdmin, bool);
  }

  async getUserAsync() {
    const user = await this._dataService.getUserFromServerAsync();
    this._store.dispatch(updateUser, user);
    this._store.dispatch(setIsAdmin, user.isAdmin);
    return user;
  }
  async setUserNullAsync() {
    this._store.dispatch(updateUser, null);
  }

  async updateUserAsync(userToUpdate) {
    const user = await this._dataService.updateUserAsync(userToUpdate);
    this._store.dispatch(updateUser, user);
  }

  async getAppointmentTypesAsync() {
    const appointmentTypes = await this._dataService.getAppointmentTypesAsync();
    this._store.dispatch(updateAppointmentTypes, appointmentTypes);
  }

  async getAppointmentRequests() {
    const appointmentRequests =
      await this._dataService.getAppointmentRequestsAsync();
    this._store.dispatch(updateAppointmentRequests, appointmentRequests);
  }

  async deleteAppointmentRequest(appointmentRequest: IAppointmentRequest) {
    await this._dataService.deleteAppointmentRequestAsync(appointmentRequest);
  }

  async postAppointmentRequest(appointmentRequest: IAppointmentRequest) {
    await this._dataService.postAppointmentRequestAsync(appointmentRequest);
  }

  async getSavedCreditCardsAsync() {
    const creditCards = await this._dataService.getSavedCreditCardsAsync();
    this._store.dispatch(updateCreditCards, creditCards);
  }
  async updateSavedCreditCardAsync(creditCard) {
    await this._dataService.updateSavedCreditCardAsync(creditCard);
  }
  async removeSavedCreditCardAsync(creditCard) {
    await this._dataService.removeSavedCreditCardAsync(creditCard);
  }

  async getPatientAppointmentRequests() {
    const appointmentRequests =
      await this._dataService.getPatientAppointmentRequestsAsync();
    this._store.dispatch(updatePatientAppointmentRequests, appointmentRequests);
  }

  async getAppointments() {
    const appointments = await this._dataService.getAppointmentsAsync();
    this._store.dispatch(updateAppointments, appointments);
  }

  async getTodaysAppointments() {
    const appointments = await this._dataService.getTodaysAppointmentsAsync();
    this._store.dispatch(updateTodaysAppointments, appointments);
  }
  async getAllAppointmentsAsync() {
    const appointments = await this._dataService.getAllAppointmentsAsync();
    this._store.dispatch(updateAllAppointments, appointments);
  }
  async updateAppointmentAsync(appointment: IAppointment) {
    await this._dataService.updateAppointmentAsync(appointment);
  }

  async postAppointmentAsync(appointment: IAppointment) {
    await this._dataService.postAppointmentAsync(appointment);
  }

  async getSubscriptionTypesAsync() {
    const subscriptionTypes =
      await this._dataService.getSubscriptionTypesAsync();
    this._store.dispatch(updateSubscriptionTypes, subscriptionTypes);
  }

  async postSubscriptionAsync(patientSubscription: any) {
    await this._dataService.postSubscriptionAsync(patientSubscription);
  }
  async postSubscriptionServicesAsync(subscriptionServices: any[]) {
    await this._dataService.postSubscriptionServicesAsync(subscriptionServices);
  }

  async getSubscriptionAsync() {
    const subscription = await this._dataService.getSubscriptionAsync();
    this._store.dispatch(updateSubscription, subscription);
  }

  async getPatientAsync(patientId) {
    const patient = await this._dataService.getPatientAsync(patientId);
    this._store.dispatch(updatePatient, patient);
  }
  async getPatientsAsync(searchTerm) {
    const patients = await this._dataService.getPatientsAsync(searchTerm);
    this._store.dispatch(updatePatients, patients);
  }
  async updatePatientAsync(patient) {
    await this._dataService.updatePatientAsync(patient);
    this._store.dispatch(updatePatient, patient);
  }

  async getActiveSubscriptionsAsync() {
    const activeSubscriptions =
      await this._dataService.getActiveSubscriptionsAsync();
    this._store.dispatch(updateActiveSubscriptions, activeSubscriptions);
  }
  async getServicesAsync() {
    const services = await this._dataService.getServicesAsync();
    this._store.dispatch(updateServices, services);
  }

  async getClientBirthdaysAsync(num: number) {
    const clientBirthdays = await this._dataService.getClientBirthdaysAsync(
      num
    );
    this._store.dispatch(updateClientBirthdays, clientBirthdays);
  }

  async getTransactionsAsync() {
    const transactions = await this._dataService.getTransactionsAsync();
    this._store.dispatch(updateTransactions, transactions);
  }
}
