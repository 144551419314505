import { State } from "./state";

export const updateUser = (state: State, user: any) => {
  const newState = Object.assign({}, state);

  newState.user = user;

  return newState;
};

export const updateAppointmentTypes = (state: State, appointmentTypes: any) => {
  const newState = Object.assign({}, state);

  newState.appointmentTypes = appointmentTypes;

  return newState;
};

export const updateAppointmentRequests = (
  state: State,
  appointmentRequests: any
) => {
  const newState = Object.assign({}, state);

  newState.appointmentRequests = appointmentRequests;

  return newState;
};

export const updatePatientAppointmentRequests = (
  state: State,
  appointmentRequests: any
) => {
  const newState = Object.assign({}, state);

  newState.patientAppointmentRequests = appointmentRequests;

  return newState;
};

export const updateAppointments = (state: State, appointments: any) => {
  const newState = Object.assign({}, state);

  newState.appointments = appointments;

  return newState;
};

export const updateTodaysAppointments = (state: State, appointments: any) => {
  const newState = Object.assign({}, state);

  newState.todaysAppointments = appointments;

  return newState;
};

export const updateAllAppointments = (state: State, appointments: any) => {
  const newState = Object.assign({}, state);

  newState.allAppointments = appointments;

  return newState;
};

export const updateCreditCards = (state: State, savedCreditCards: any) => {
  const newState = Object.assign({}, state);

  newState.savedCreditCards = savedCreditCards;

  return newState;
};

export const updateSubscriptionTypes = (
  state: State,
  subscriptionTypes: any
) => {
  const newState = Object.assign({}, state);

  newState.subscriptionTypes = subscriptionTypes;

  return newState;
};
export const updateSubscription = (state: State, subscription: any) => {
  const newState = Object.assign({}, state);

  newState.subscription = subscription;

  return newState;
};

export const updatePatient = (state: State, patient: any) => {
  const newState = Object.assign({}, state);

  newState.patient = patient;

  return newState;
};

export const updatePatients = (state: State, patients: any) => {
  const newState = Object.assign({}, state);

  newState.patients = patients;

  return newState;
};

export const updateActiveSubscriptions = (
  state: State,
  activeSubscriptions: any
) => {
  const newState = Object.assign({}, state);

  newState.activeSubscriptions = activeSubscriptions;

  return newState;
};

export const updateServices = (state: State, services: any) => {
  const newState = Object.assign({}, state);

  newState.services = services;

  return newState;
};

export const updateClientBirthdays = (state: State, clientBirthdays: any) => {
  const newState = Object.assign({}, state);

  newState.clientBirthdays = clientBirthdays;

  return newState;
};

export const updateTransactions = (state: State, transactions: any) => {
  const newState = Object.assign({}, state);

  newState.transactions = transactions;

  return newState;
};

export const setIsAdmin = (state: State, isAdmin: boolean) => {
  const newState = Object.assign({}, state);

  newState.isAdmin = isAdmin;

  return newState;
};
