import { PatientAppointments } from "../pages/all-appointments";
import { ISubscription } from "aurelia-router";
import {
  IAppointment,
  IAppointmentRequest,
  IAppointmentType,
  ICreditCard,
  IService,
  ITransaction,
  IUser,
} from "./interfaces";

export interface State {
  user: IUser;
  appointmentTypes: IAppointmentType[];
  appointmentRequests: IAppointmentRequest[];
  patientAppointmentRequests: IAppointmentRequest[];
  savedCreditCards: any[];
  appointments: IAppointment[];
  subscriptionTypes: any[];
  subscription: ISubscription;
  patient: IUser;
  patients: IUser[];
  activeSubscriptions: ISubscription[];
  todaysAppointments: IAppointment[];
  allAppointments: IAppointment[];
  services: IService[];
  clientBirthdays: IUser[];
  transactions: ITransaction[];
  isAdmin: boolean;
}

export const initialState: State = {
  user: null,
  appointmentTypes: [],
  appointmentRequests: [],
  savedCreditCards: [],
  patientAppointmentRequests: [],
  appointments: [],
  subscriptionTypes: [],
  subscription: null,
  patient: null,
  patients: [],
  activeSubscriptions: [],
  todaysAppointments: [],
  allAppointments: [],
  services: [],
  clientBirthdays: [],
  isAdmin: null,
  transactions: [],
};
