import { autoinject } from "aurelia-framework";
import { config } from "../config";
import moment from "moment";

@autoinject()
export class AuthenticationService {
  session: ITokenObject;
  isAdministrator: boolean = false;

  constructor() {
    // this._stateManager = stateManager;

    const sessionToken = localStorage[config.tokenName] || null;

    if (sessionToken != null) this.session = JSON.parse(sessionToken);
  }

  async loginAsync(email: string, password: string) {
    let fetchUrl = `/login`;

    const returnedData = await fetch(fetchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    if (returnedData.status == 403) {
      return "Inactive Subscription";
    }

    try {
      const session = await returnedData.json();

      if (session == "Email Not Confirmed") {
        return "Please Confirm Your Email Address";
      }
      if (session == "Account Not Found") {
        return "Unknown User";
      }
      if (session == "Name taken") {
        return "Name Taken";
      }

      localStorage[config.tokenName] = JSON.stringify(session);
      this.session = session;

      const userData = await fetch("Users", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${session.token}`,
        },
      });
      try {
        const user = await userData.json();
        if (user.isAdmin) {
          this.isAdministrator = true;
        } else {
          this.isAdministrator = false;
        }
      } catch {
        return "No User";
      }

      //this._aurelia.setRoot("app");
      //set the value of userAuthenticated in app.ts to true

      return "Login Successful";
    } catch (exception) {
      return this.session;
    }
  }

  async registerAsync(
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    password: string,
    passwordRepeat: string,
    wantsNewsletter: boolean,
    birthday: Date
  ) {
    let fetchUrl = `/register`;

    const returnedData = await fetch(fetchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        password: password,
        passwordRepeat: passwordRepeat,
        wantsNewsletter: wantsNewsletter,
        birthday: birthday,
      }),
    });

    try {
      const data = await returnedData.json();
      return data;
    } catch (exception) {
      return this.session;
    }
  }

  logout() {
    // Clear from localStorage
    localStorage[config.tokenName] = null;

    // .. and from the session object
    this.session = null;

    // .. and set root to login.
    // this._aurelia.setRoot("login-register");
  }

  sendConfirmationEmail(email: string) {
    let fetchUrl = `/verification`;

    fetch(fetchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    });
  }

  sendPasswordResetEmail(email: string) {
    let fetchUrl = `/resetpassword`;

    fetch(fetchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    });
  }

  async resetPassword(
    password: string,
    confirmPasssword: string,
    token: string,
    email: string
  ) {
    let fetchUrl = `/resetpassword`;

    const returnedData = await fetch(fetchUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        password: password,
        passwordRepeat: confirmPasssword,
        token: token,
        email: email,
      }),
    });

    try {
      const data = await returnedData.json();
      return data;
    } catch (exception) {
      return this.session;
    }
  }

  isAuthenticated() {
    return this.session !== null;
  }

  static isLoggedIn(): boolean {
    let sessionToken = localStorage[config.tokenName];

    if (sessionToken == null || sessionToken.indexOf("token") == -1)
      return false;

    let tokenObject = JSON.parse(sessionToken);

    return AuthenticationService.confirmOrInvalidate(tokenObject);
  }
  static confirmOrInvalidate(tokenObject: ITokenObject): boolean {
    if (tokenObject == null) return false;

    if (moment(tokenObject.expiration).diff(moment()) < 0) {
      localStorage[config.tokenName] = null;
      return false;
    }

    return true;
  }
}

export interface ITokenObject {
  token: string;
  expiration: string;
}
